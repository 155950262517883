@import url('constants.less');
@import  url('../App.less');


/* Body */
html,body{
    //height:100%;
    //color: #000305;
     font-family: Futura, Helvetica, sans-serif;
/*      font-family: 'Oswald', sans-serif;*/
    //margin: 0;
    //padding: 0em;
    //text-align: left;
    //margin: 0em 0em 0em 0em;
   .unselectable;
  // background-color: #444444;

  }

p{
    letter-spacing: .02em;
}

a::after{
  //  text-align: center;
   // transform: translateX(-0% + 0);
    
   justify-content: center;
}
[data-tooltip]:hover::after {
    display: block;
    position: absolute;
 //   text-align: center;
    content: attr(data-tooltip);
    border: 1px solid black;
    background: #FFFFFF66;
    color: #eeeeeeFF;
    padding: .4em;
    .allroundcorners(.7em);
    
  }
  
Header {
    margin: 15vh 1.5em Auto 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //font-size: calc(10px + 2vmin);
    //color: white;
    color: #282c34;
}

.AltSec {
    padding: 2em 0;
    margin: 0em 0 0em 0;  
    
}

.AltSecB {
    .AltSec;
    color: @almost-white;
    background-color: #000; 
}

.AltSecW {
   .AltSec;
    color: @std-text-color;
    background-color: #FFF; 
}




.Home {
    align-items: start;
    //border: 2px solid yellow;
    margin: 0;
    padding: 0;
    //background-color: #282c34;
   // background-color: #0088FF;
    .top {
        color: @almost-white;
         height:100%;
        // background:#BBB;
         height: auto;
         .cardContainer {
             // display: grid;
             // grid-gap: 1rem;
             // grid-template-columns: repeat(3, .3fr); 
     
             display: flex;
             flex-wrap: wrap;
             justify-content: center;        
 
         }

         h4{ 
             margin: 0;
         }
 
         img{
             align-self: center;
             width: 100%;
             max-width: 12em;
             height: 100%;
             //aspect-ratio:1/2 ;
             //box-sizing: border-box;
             
         }
         .card {
             text-align: center;
             background-color: #00000000;
             color: @almost-white;
         
        
         //    display: flex;
         //    flex-direction: column;
         //    padding: 1rem;
         //    justify-content: space-between;
 
            //flex: 1 1 250px; /*  Stretching: */
            flex: 0 1 250px; /*  No stretching: */
            margin: 5px;
        
 
 
         }
 
     }


    .top1 {

       
        margin: 0;
        padding: 0;
      
        height:100%;
        
        height: auto;
        .cardContainer {
            //margin-bottom: 2em;
            // margin: 0 1.5em;

            // display: grid;
            // grid-template-columns: minmax(250px, 25%) 1fr;


            // display: grid;
            // grid-gap: 1rem;
            // grid-template-columns: repeat(3, .3fr); 
    
            display: flex;
            flex-wrap: wrap;
            justify-content: center;        

        }
 

        label, h4, h5{ 
            display: block;
            text-align: center;
            margin: 0;
        }
        

        img{
            margin-left: .5em;
            margin-right:.5em;
           // display: inline-block;
            //width: 100%;
            //max-width: 12em;
            height: 100%;
            max-height: 25em;
            //min-width: 2em;
            //max-width: 35em;
            ;
            //aspect-ratio:1/2 ;
            //box-sizing: border-box;
            
        }
        .card {
           // text-align: center;
           // background-color: #00000000;
            color: @almost-white;
        
       
        //    display: flex;
        //    flex-direction: column;
        //    padding: 1rem;
        //    justify-content: space-between;

           //flex: 1 1 250px; /*  Stretching: */
           flex: 0 1 250px; /*  No stretching: */
           //margin: 5px;
       


        }

    }

}

.app-header {
    //text-align: center;
    //background-color: #282c34;
    //background-color: #282c34;
    //height: 100%;
    //min-height: 50vh;
    //margin-top: 15vh;
    margin: 15vh 1.5em Auto 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //font-size: calc(10px + 2vmin);
    //color: white;
    color: #282c34;
}



.App-header {
    //border: 2px solid yellow;
    .App-header();
    h3,h5 {
        text-align: center;

      //  color: green;
    }
}

h3,h5 {

    text-align: center;
}


.gPlayLink {
    height: 5em;
}

@media(max-Width:575px) {
    .business-card{
        //    visibility: visible
        margin:0 auto 0 50%;
        transform: translateX(-50%);
        right: auto;
    }
    .gPlayLink {
        height: 4em;
       // max-width: 10em;
    }

    .screenshot_profile{
        margin-left: .25em;
        margin-right:.25em;
        max-width: 8em;
    }
    .screenshot_landscape{
        margin-left: .25em;
        margin-right:.25em;
        max-width: 8em;
    }
}
/* sm */
@media(min-Width:576px) and (max-Width:767px) {
    .screenshot_profile,.screenshot_landscape{
        margin-left: .5em;
        margin-right:.5em;
        max-width: 9em;
    }
}
/* md */
@media(min-Width:768px) and (max-Width:991x) {
}
/* lg */
@media(min-Width:992px) and (max-Width:1199x) {
}
/* xlg */
@media(min-Width:1200) {
}
/* xxlg */
@media(min-Width:1200) {
}
 

