@import url('../constants.less');

.PanelSupport {
  //border: 2px solid yellow;
  background-color: #fff;
  padding-bottom: 2em;
  padding-top: .5em;
  margin-top: 0;


  a, a:hover {
    font-size: 1.67em;
    text-decoration: none;
    color: #333333FF;    
  }

  a:hover {
    color: #335599FF;    
  }

 
  ul {
    list-style-type: disc;
  }

  h4 {
    
    //border: 1px solid black;
    // //height: .8em;
    height: auto;
    margin: 0;
    padding: 0;

  }

  h5 {

    //border: 1px solid black;
    height: auto;
    margin: 0 0 .3em 0;
    padding: 0;
    text-align: left;
  }


  .parent {
    display: flex;
    flex-wrap: wrap;
  }
  
  
  .child {
    padding: 0 .5em 0 .5em;
    //  flex: none | [ <'flex-grow'> <'flex-shrink'>? || <'flex-basis'> ]
    //  If we don't want the items to stretch:
    flex: 0 1 300px;
    //  If we do want the items to stretch:
    flex: 1 1 300px;
    
    //   etc.
    //border: 1px solid red;
    //background: lightpink;
    font-size: 1.2rem;
    line-height: 1.2em;
    text-align: center;
  }
  
  
  .time-span {
    font-size:smaller;
    vertical-align:baseline;
    line-height: 1em;
   
  }
  
  
  .right-column {
    float: right;
  }
  
  table, tr, td, li{
    padding:  0;
    //  border: 1px solid rebeccapurple;
     // width: 100%;
  }
  
  
  p {
    text-indent: 0;
    text-align: justify;
  }
  .mainjamme {
    padding-top: 70px;
  }
  #savepdf {
    position: absolute; 
    margin-left:50%;
    margin-right: auto;
    transform: translateX(-50%);
    z-index: 1;
  }
  

  img{
    //max-height: 20em;
    /*
    
    object-fit: n;
    */
    //max-width: 90%;
    object-fit: contain;
    
    max-width: 100%;

    // height: 100%;
    box-shadow: 3px 5px 10px #888888;
    max-height: 600px;
  
    //border: 1px solid #808080;
    border-radius: 7px;
  }


  .whimg {
    img {
      box-shadow: none;
      border: none;
    }
  }



.app-photo {
  margin-top: 50px;
  max-height:500px;
  margin-bottom: 55px;


  img{
    //width: auto;
    /*
   
    object-fit: n;
    */

    box-shadow: 5px 5px 10px #888888;
    max-height: 500px;
    max-width: 100%;
    border: 1px solid #808080;
    border-radius: 3px;
  }
  p{
    margin-top: .9em;
    text-align: center;
  }
}




  @media print  { .noprint  { display: none; }}
  
  @media screen { .noscreen { display: none; } }

  /* XSmall */
  @media (max-width: 575px) {

    .business-card{
        //    visibility: visible
        margin:0 auto 0 50%;
        transform: translateX(-50%);
        right: auto;
    }
    .mainjamme{
      padding-top: 50px;
    }
    .right-column{float: none;}
    #savepdf {
      visibility:hidden;
      right: 0;;
    }
    ul {
      font-size: 14px;
      // list-style-type:none;
      // list-style: none;
        margin: 0 0 0 1.2em;
        padding: 0 0 0 .3em;
      }  

      .app-photo>img {width: 100%;}
  }
    
  /* Small */
  @media (min-width: 576px) and (max-width: 767px) {
    .right-column{float: none;}
    #savepdf {
      right: 0;;
      visibility:hidden;
    }
    .app-photo>img {width: 100%;}
  }
  
  /* Medium */
  @media (min-width: 768px) and (max-width: 991px)    {
    .app-photo>img {width: 100%;}
  }
  
  /* Large */
  @media (min-width: 992px) and (max-width: 1199px) {
  }
  
  /* Large */
  @media (min-width: 1200px) {
  }
  







  
}