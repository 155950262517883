@import url('../constants.less');


#business_card {
    //position: absolute;
    //bottom: 5em;
    //right: 1em;
    
    img{
        height: 40px;
        width: 40px;
        float: right;
    }

    li{
        font-size: 16px;
        font-weight: 600;
        line-height: 1.1em;
        padding: 0;
        margin: 0;
       // margin-bottom: .5em;
    }



    ul  {
        font-size: 16px;
        font-weight: 600;
        list-style:none;
        list-style-type: none;
        ::before{
            content:"";
            margin-bottom:30px;
        }
         
        h4{
            margin-top: .3em;
            margin-bottom: 0;
            display: inline-block;
        }
        h5{
            text-align: left;
            margin-bottom: 1.1em;
        }
        height: auto;
        width:290px;
        float:right;
        margin:0;
        padding: .8em;
        border:1px solid grey;
    //  background-color: #f9f9f9;
        background-image: url("../../assets/images/card-texture.jpg");
        background-size: cover;
        .allroundcorners(5px);
    }

    // @media (orientation: landscape) {
    //     visibility: hidden;
    // }
    
    li {
        width: 100%;
     }
     li:last-child {
        margin-top: .5em;
     }
  
    .justifyRight {
        float: right;
        padding: 0;
        margin: 0;
    }
}



        @media (orientation: landscape) and (max-width: 767px) {
            #business_card {
                visibility: hidden;
            }
        }


      /* XSmall */
      @media (max-width: 575px) {

        #business_card ul>h5{
            margin-bottom: 3em;
        }
         #business_card ul{
            width:270px;
           // height: 150px;
            float: none;
            margin-left: auto;
            margin-right: auto;
         }
        @media (orientation: landscape) {
            #business_card {
                visibility: hidden;
            }
        }

    }

    /* Small */
    @media (min-width: 576px) and (max-width: 767px) {

    }

    /* Medium */
    @media (min-width: 768px) and (max-width: 991px)    {
    }

    /* Large */
    @media (min-width: 992px) and (max-width: 1199px) {
    }

   /* XLarge */
   @media (min-width: 1200px)
   {

}
