@import url('../constants.less');


/* CSS Document */
@media screen {
}
/// nav


.header-boxes-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.box {
  grid-column: 1 / 2;
  flex:1;
  margin:auto 0;
  justify-content: left;
  //transform: translateY(-50%);
}

.boxm {
  grid-column: 2 / 3;
  display: grid;
  flex:3;

  margin: 0;

  justify-content: center;
}

.right-menu-box {
  //grid-column: 3 / 3;
  //display: grid;
  flex:1;
  width: auto;
  margin:auto;
  //padding: 0 0px 0 0;
  text-align: right;



}



.right-menu-box  a {
    color:#000;
    display: inline-block;
    margin:.2em;
    padding: 0;
    width: auto;
}



.navbar{
    .container{
        border-right:2px outset rgba(243, 243, 243, .4)!important;
        border-left:2px outset rgba(243, 243, 243, .4)!important;
        border-bottom:2px outset rgba(243, 243, 243, .4)!important;
    }
}

.navbar-toggle{
     display: inline-block;
    right:@full-page-fluid-margin;
    //top:3px;
    // position: absolute;
    vertical-align: middle;
     .allroundcorners(4px);
    min-width:25px;
     margin:2px;
     padding:4px 3px 4px 3px;
     cursor: pointer;
}


// Ensure the 'hamburger' menu is visable
 .icon-bar {
    display: block;
    z-index: 1000;
    margin-top: 0px;
    margin-bottom: 4px;
    height: 1px;
    padding:0;
    border-bottom: 2px solid rgb(20, 20, 20);
    background-color: rgb(49, 49, 49);
    box-shadow: 0px 0px 2px 0px #555;
    // border-bottom: 1px solid rgb(206, 206, 206);
    // background-color: rgb(49, 49, 49);
    // box-shadow: 0px 0px 2px 0px #bbbbbb;

}

.nav{
   // background-color:white;
    margin-right:50px;
    font-size:15px;
    font-weight:500;
}

.nav li>a{
    // these two are for vertical centering
    display: table-cell;
    vertical-align: middle;
   // padding:5px 0;
    margin-left:0;
    margin-right:0;
    padding-left:4px;
    padding-right:4px;
    color:#e7e7e7;

}
.nav li{
    list-style: none;
    list-style-type: none;
    // for vertical centering
    display: table;
 //   padding:0 5px 0 5px;
    margin-right:15px;
    color:#000;
}
.nav li:last-child {
    a{
      //  color:#efefef!important;
       // padding:5px 9px;
    }
}

.nav:hover,.nav li:hover, .nav a, .nav a:hover, .nav a:visited, .nav a::selection{
 //   background-color:transparent;
    color:#000;

}

.sidebar-nav {
    position: absolute;
    top: 0;
    width: 250px;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .sidebar-nav li {
    line-height: 50px;
    text-indent: 20px;
  }

  .sidebar-nav li a {
    color: #ffffff;
    display: block;
    text-decoration: none;
  }

  .sidebar-nav a:hover {
    color: #fff;
    background: rgba(255,255,255,0.2);
    text-decoration: none;
  }

  .sidebar-nav li a:active, .sidebar-nav li a:focus {
    text-decoration: none;
  }

  .sidebar-nav > .sidebar-brand {
    height: 55px;
    line-height: 55px;
    font-size: 18px;
  }

  .sidebar-nav  a {
    color:@text-color-blue;
    font-family:'Arvo', sans-serif;
    font-size:20px;
    padding:0;
    line-height:1.5em;
  }

  .sidebar-nav > .sidebar-brand {
    color: #FFF;;
    font-size:20px;
    font-weight:700;
  }

  .sidebar-nav > .sidebar-brand a:hover {
    color: @text-color-blue;
    background: none;
  }



///nav
#sidebar-wrapper {

  box-sizing: border-box;
  h4{
    padding: 0;
  }

  position: relative;
  nav a{
    text-align: left;
    font-size: 18px;
    line-height: 1.4em;
  }

  nav a::before{
      content: "►";
      font-size: .5em;
      padding-right: 1.5em;
      vertical-align: middle;
  }

  p {
    margin: .5em Auto;
    font-size: 20px;
    color:rgb(175, 168, 130);
  }
  a {
    font-size: 14px;
    color:rgb(175, 168, 130);
  }

  a:hover {
    color:rgb(31, 118, 199);
  }

  .center{
      text-align:center;
      text-shadow: #000;
  }

  background-color:rgba(0,0,0,.7);
  font-weight:500;
  //  margin:0%;
  //  padding:0 3% 3% 3%;
  //color:#FFFFDF;
  
  border:2px ridge aqua;
  .leftroundcorners(20px);

  padding: 1.2em .8em .8em .8em;

  margin-top:20px;
  margin-right: -251px;
  right: 0;
  width: 250px;
 //  background: @std-nav-background-color;
  position: fixed;
  height: 180px;
  overflow-y: auto;
  z-index: 1000;
  transition: all 0.5s ease-in 0s;
  -webkit-transition: all 0.5s ease-in 0s;
  -moz-transition: all 0.5s ease-in 0s;
  -ms-transition: all 0.5s ease-in 0s;
  -o-transition: all 0.5s ease-in 0s;
}


 #sidebar-wrapper.active {
    right: 250px;
    width: 250px;
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
    -moz-transition: all 0.5s ease-out 0s;
    -ms-transition: all 0.5s ease-out 0s;
    -o-transition: all 0.5s ease-out 0s;
  }

  .item1{
    grid-area: 1 / 2 / span 1 / span 1;
  }

  .banner-text-wrapper{

     // margin:17px 0 0 0;
    //  padding:0;
    display: grid;
    grid-template-areas: 'bannertext bannertext bannertext';
      font-family:Oswald;
      color:white;
     // float:none;
      width: 100%;
     // height: 100%;
      //margin-top: .6em;
     // margin-bottom: .3em;
     justify-content: center;
     place-items: center;
     height: auto;
  }

    .page-logo  {
        padding:5px 0;
        
        //height: 100%;
        // height: 80px;
       //  padding-top:50%;
       //  padding-bottom: auto;
        // transform: translatey(-50%);

         // padding-top: .4em;
         // padding-bottom: .4em;
         //position: relative;
         display: table-cell;

         vertical-align: middle;
         float:left;
         text-shadow: 0em 0em 4px #dedede;
             img{
           
              
                 max-height:45px;
                 min-height:30px;
                 width: auto;

                 //width: 20%;
                 //height: 50px;
                 //max-width:300px;
                 //min-width: 120px;
             }
            p{
                 text-align: right;
                 right:0;
                 bottom: 0;
                 margin: 0;
                 padding: 0 .25em 0 0;
             }
             h1{
                 line-height:1em;
                 letter-spacing:.05em;
             }
         }


    .bannerbackground {
        background-color:rgb(225, 225, 225);
        opacity:.7;
    }




  #header {
        .bannerbackground;
       // .bottom-round-corners(5px);
        opacity:1;
        color:white;
        z-index:1;
        position:fixed;
        width:100%;
        height: auto;
        display:inline;
        padding:0em 1.0em 0em 1.0em;
        margin: 0em 0em 0em 0em ;
        text-align:Left;


    h1 a:link, h1 a:visited {
        color: #000305;
        display: block;
        font-weight: bold;
        margin: 0 0 0 0;
        padding: 0;
        text-decoration: none;
        width: 100%;
    }

    h1 a:hover, h1 a:active {
        background: none;
        color: #C74350;
        text-shadow: none;
    }


    /* Main Nav */
    nav {
 //       font-family:Constantia;
        font-family:'Arvo', sans-serif;

        font-size: 1.5em;
        display:inline-block;
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        text-align: center;
        border-radius: 5px;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
    }


  nav ul {list-style: none; width:auto; background: #100D48; margin: 0 0 0 0; padding:0 0 0 0; }

  nav li {float: left; display: block; width:auto; margin: 0 0 0 0; padding:0 0 0 0; }


/*  nav ul {list-style: none; margin: 0 1px; width: auto; background: linear-gradient(black, white, black);}
*/
/*  nav li {float: left; display: block; margin: 0px;  background: linear-gradient(black, maroon, black); }
*/
  /* Menu Links */
   nav a:link, nav a:visited {
    color:#FBF4D9;
    display: block;
/*    height: 100%;
*/    margin: 0 0 0 0; padding:0 .4em 0 .4em;
    text-decoration: none;
  }

  /* Mouse Over */
  nav a:hover, nav a:active,
  nav .active a:link, nav .active a:visited {
/*    background: #475411;
*/    color: #3f3;
      text-decoration:none;
/*    text-shadow: 2px;
   	border-radius: 3px;
   	-moz-border-radius: 3px;
   	-webkit-border-radius: 3px;
*/
  }

  nav li a {
    background: #100D48;
      padding:0 0 0 .5em;
  }
  nav li:first-child a {
/*    background: #100D48;
*/
/*    border-top-left-radius: .2em;
    -moz-border-radius-topleft: .2em;
    -webkit-border-top-left-radius: .2em;

*/
/*    border-bottom-left-radius: .2em;
    -moz-border-radius-bottomleft: .2em;
    -webkit-border-bottom-left-radius: .2em;
*/
  }
  nav li:last-child a {

    background: #100D48;

/*    border-top-right-radius: .2em;
    -moz-border-radius-topright: .2em;
    -webkit-border-top-right-radius: .2em;
*/

    border-bottom-right-radius: .4em;
    -moz-border-radius-bottomright: .4em;
    -webkit-border-bottom-right-radius: .4em;

  }

}


#content {
    position: relative;
    background: green;
}
#content:after {
    content:'';
    display: block;
    clear: both;
}
.scroll {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: auto;
}
.temp {
    height: 1000px;
}

 /*********************** BOOTSTRAP SIZING *******************************************/

/* XSmall */
    @media screen and (max-width: 576px) {

      h3{
        font-size: 18px!important;
        
      }
      h4{
        font-size: 16px!important;
      }
      h5{
        font-size: 12px!important;
      }
      // .page-logo img {
      //   margin-top:0px;
      //   height: 40px;
      // }

      // .banner-block{
      //     display: block;
      //     width:100%;
      //     h4{
      //         text-align: center;
      //         font-size: 16px;
      //         //opacity: 0;
      //     }
      // }
      // .banner-text-wrapper{
      //    margin-top: 7px;
      //  }
    }

    /* Small */
    @media (min-width: 576px) and (max-width: 767px) {
    //     .box {
    //       flex:1 0;
    //     }
    //     .page-logo h1{
    //         font-size:xx-large;
    //     }
    //     // .page-logo{
    //     //     float:none;
    //     //     text-align:center;
    //     // }
    //     .banner-text-wrapper{
    //         margin-top: .4em;
    //         margin-bottom: .6em;
    //     }
    }

    /* Medium */
    @media (min-width: 768px) and (max-width: 991px)    {
    }

    /* Large */
    @media (min-width: 992px) and (max-width: 1199px) {
    }

    /* X-Large */
    @media screen and (min-width: 1200px)
    {
    }

    li {
      listStyleType:None ;
    }

