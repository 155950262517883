
@import url('../constants.less');


/* CSS Document */
@media screen
{

   .article   {
      p {
         text-indent: .5em;
         //font-size: 1.3em;
      }
   }

   .ImageCarousel > img {
      object-fit: contain;
      box-shadow: 5px 5px 10px #888888;
      border: 1px solid #808080; 

      max-width: 100%;
      width: 100%;
      height: 100%;

   }

  .ImageCarousel{
   //   width: 100%;
   //   height: 100%;
      img {
       }      
//    display: block;
//    //box-shadow: none;
   }

   .img-slider-img {
      object-fit: contain;
      //margin-left: auto;
      //margin-right: auto;
      max-width: 100%;
      height: 100%;
      display: block;
    //  margin-left: 50%;
     // transform: translateX(-50%);
      flex-shrink: 0;
      flex-grow: 0;
      // aspect-ratio: 4 x 4;

    }
   
   .img-slider-btnl, .img-slider-btnr {
      all: unset;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      padding: 0rem;
      cursor: pointer;
      transition: background-color 400ms ease-in-out ;
      //.allroundcorners(10px);
    
   }
   .img-slider-btnl {  
      .roundcorners-left(10px);
      left: 0;
   }
   .img-slider-btnr {
      .roundcorners-right(10px);
      right: 0;
   }


   .img-slider-btnl:hover, .img-slider-btnr:hover {
      background-color: rgba(0,0,0,0.2);
      
   }
    .img-slider-btnl > *, .img-slider-btnr > * {
      
       stroke: white;
       fill: black;
       width: 2rem;
       height: 2rem;
      
    }


}
/*********************** BOOTSTRAP SIZING *******************************************/

/* XSmall */
@media screen and (max-width: 450px)
{
/*   .footerarea { font-size:x-small;}
   .footertext
   {
      font-size:x-small;
   }
   .footertext p 	{ font-size: 10px;}
*/
}

/* Small */
@media screen and (min-width: 451px) and (max-width: 849px)
{
/*   .footerarea { font-size:small;}
   .footertext { font-size:small;}
   .footertext  p 	{ font-size: 12px;}
*/
}

/* Medium */
@media screen and (min-width: 850px) and (max-width: 1199px)
{
/*   .footerarea { font-size:medium;}
   .footertext { font-size:medium;}
   .footertext p 	{ font-size: 14px;}
*/}

/* Large */
@media screen and (min-width: 1200px)
{
/*   .footerarea { font-size:large;}
   .footertext { font-size:large;}
   .footertext p 	{ font-size: 14px;}
*/
}
